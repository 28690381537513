import React, { useState,useEffect } from 'react';
import { NavLink,useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import {Ripple} from "primereact/ripple";
import { Badge } from 'primereact/badge';
import * as Iconsax from "iconsax-react";

import {getPermisoModulo} from "./service/PermisosService"
import  {ListarSeccion}from "./service/LiderInService.js"
import { ListarSeccionCapacitacion } from './service/CapacitacionService';
import { ObtenerListaSeccionClima } from './service/ClimaService';
import useUsuario from './hooks/useUsuario';
import { RegistrarAcceso } from './service/InteraccionService';
import * as constantes from "./constants/constantes.js";
import {EncriptarUrlCodigoUsuario} from "./service/SomosinService.js"
import { ObtenerUsuarioPorId } from "./service/UsuariosService";

const AppSubmenu = (props) => {

    const [activeIndex, setActiveIndex] = useState(null)
         const GuardarAcceso = async (nombre) => {
            let jwt = window.localStorage.getItem("jwt");
            let seccion = nombre
            let url = window.location.href;
            let jsonAcceso = JSON.stringify({seccion,url},null,2)
            await RegistrarAcceso({jsonAcceso,jwt});
        };
    const { permisos,perfil} = useUsuario();
    const [criptoUsuario,setCriptoUsuario] = useState()
    const [ObtenerUsuarioPorIdData, setObtenerUsuarioPorId] = useState([])
    
    useEffect(() => {
        const getUsuario = async () => {
            let jwt = window.localStorage.getItem("jwt");
            let Id = perfil.idUsuario
            await ObtenerUsuarioPorId({ jwt, Id }).then(data => {
                setObtenerUsuarioPorId(data)
            })
        }
        if (perfil) getUsuario()
    }, [perfil])

    useEffect(() => {
        const encriptarUrlCodigoUsuario = async()=>{
            let EncryptUrl = perfil.codigoUsuario;
            //console.log("EncriptarUrlCodigoUsuario",EncryptUrl)
            let json = JSON.stringify({EncryptUrl},null,2)
            let jwt = window.localStorage.getItem("jwt");
            await EncriptarUrlCodigoUsuario({ jsonEncryptUrl : json, jwt : jwt}).then(data=>{
                var res = data.encryptUrl.replace("\\","%2F")
                setCriptoUsuario(res)
                //setCriptoUsuario("WPj5xzmf3KIsv8G8JIv7SdTyhjqr%2fpIKSUzDtrxq6OA=")
            })
        }
        if(perfil!=undefined && perfil!=null ){
            encriptarUrlCodigoUsuario();
        } 
    },[perfil])

    const onMenuItemClick = (event, item, index) => {
        //avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        //execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
        }

        if (index === activeIndex)
            setActiveIndex(null);
        else
            setActiveIndex(index);

        if (props.onMenuItemClick) {
            props.onMenuItemClick({
                originalEvent: event,
                item: item
            });
        }
    }

    const onKeyDown = (event) => {
        if (event.code === 'Enter' || event.code === 'Space'){
            event.preventDefault();
            event.target.click();
        }
    }

    const renderLinkContent = (item) => {
        let submenuIcon = item.items && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>;
        let badge = item.badge && <Badge value={item.badge} />
        return (
            <React.Fragment>
                {/* <i className={item.icon}></i> */}
                {
                    item.icon?<i>{item.icon}</i>:<i className="pi pi-circle-fill" style={{'fontSize': '8px'}}></i>
                }
                
                <span>{item.label}</span>
                {submenuIcon}
                {badge}
                <Ripple/>
            </React.Fragment>
        );
        
    }

    const renderLink = (item, i) => {
        let content = renderLinkContent(item);

        if (item.to) {
            return (
                // <NavLink aria-label={item.label} onKeyDown={onKeyDown} role="menuitem" className="p-ripple" activeClassName="router-link-active router-link-exact-active" to={item.to} onClick={(e) => onMenuItemClick(e, item, i)} exact target={item.target}>
                <NavLink aria-label={item.label} onKeyDown={onKeyDown} role="menuitem"  className={({isActive})=>(isActive ? 'router-link-active router-link-exact-active' : 'p-ripple')} to={item.to} onClick={(e) => {
                    onMenuItemClick(e, item, i)
                    if(item.seccion)GuardarAcceso(item.seccion)
                }} target={item.target}>
                    {content}
                </NavLink>
            )
        }
        else {
            var urlFinal = null
            if (item.opcion) {
                if (item.opcion == 1 || item.opcion == 2) {
                    urlFinal = item.url + criptoUsuario + "\\" + item.opcion
                }
                else if(item.opcion ==3){
                    console.log("ObtenerUsuarioPorIdData",ObtenerUsuarioPorIdData)
                    if(ObtenerUsuarioPorIdData.idMarca==2){
                        urlFinal = constantes.gdhenlineaUrlCuponeraIdat
                    }
                }
                return (
                    <a tabIndex="0" aria-label={item.label} onKeyDown={onKeyDown} role="menuitem" 
                    href={urlFinal == null ? item.url: urlFinal} className="p-ripple" 
                    onClick={(e) => onMenuItemClick(e, item, i)} target="_blank">
                        {content}
                    </a>
                );
            }
            else{
                return (
                    <a tabIndex="0" aria-label={item.label} onKeyDown={onKeyDown} role="menuitem" 
                    href={urlFinal == null ? item.url: urlFinal} className="p-ripple" 
                    onClick={(e) => onMenuItemClick(e, item, i)} target={item.target}>
                        {content}
                    </a>
                );
            }
        }
    }

    let items = props.items && props.items.map((item, i) => {
        let active = activeIndex === i;
        let styleClass = classNames(item.badgeStyleClass, {'layout-menuitem-category': props.root, 'active-menuitem': active && !item.to });


        if(props.root) {
            return (
                <li className={styleClass} key={i} role="none">
                    {props.root === true && <React.Fragment>
                        <div className="layout-menuitem-root-text" aria-label={item.label}>{item.label}</div>
                        <AppSubmenu items={item.items} onMenuItemClick={props.onMenuItemClick} />
                    </React.Fragment>}
                </li>
            );
        }
        else {
            if(item.visible)
            {
                return (
                    <li className={styleClass} key={i} role="none">
                        {renderLink(item, i)}
                        <CSSTransition classNames="layout-submenu-wrapper" timeout={{ enter: 1000, exit: 450 }} in={active} unmountOnExit>
                            <AppSubmenu items={item.items} onMenuItemClick={props.onMenuItemClick} />
                        </CSSTransition>
                    </li>
                );
            }
            
        }
    });

    return items?  <ul className={props.className} role="menu">{items}</ul> : null;
}

export const AppMenu = (props) => {
    const [menuEva, setMenuEva] = useState([{label: "", items:  []}]);
    const { permisos,perfil} = useUsuario();
    const [listaSeccionLiderIn, setListaSeccionLiderIn] = useState(null);
    const [listaSeccionCapacitacion, setListaSeccionCapacitacion] = useState(null);
    const [listaSeccionClima, setListaSeccionClima] = useState(null);
    useEffect(()=>{
        const obtenerSeccionLiderIn =async ()=>{
            let jwt = window.localStorage.getItem("jwt");
           await ListarSeccion({jwt}).then(data=>
            {
                let arrayTemp = []
                data.map((item,i)=>{
                    arrayTemp.push({label:item.nombre,to:"LiderIn/Seccion/"+item.id, visible:true,seccion:"LiderIn/"+item.nombre})   
                })
                setListaSeccionLiderIn(arrayTemp)
            })
           
        }
        if(!listaSeccionLiderIn){
            obtenerSeccionLiderIn();
        } 
    },[])


    useEffect(()=>{
        const obtenerSeccionCapacitacion =async ()=>{
            let jwt = window.localStorage.getItem("jwt");
           await ListarSeccionCapacitacion({jwt}).then(data=>
            {
                let arrayTemp = []
                data.map((item,i)=>{
                    switch (item.id) {
                        case 1:
                            arrayTemp.push({label:item.nombre,to:"Capacitacion/Seccion/"+item.id+"/DetalleIntro", visible:true,seccion:"Capacitación/"+item.nombre})   
                            break;
                        case 2:
                            arrayTemp.push({label:item.nombre,to:"Capacitacion/Seccion/"+item.id+"/DetalleMia", visible:true,seccion:"Capacitación/"+item.nombre})   
                             break;
                        case 3:
                            arrayTemp.push({label:item.nombre,to:"Capacitacion/Seccion/"+item.id+"/ListaOpcional", visible:true,seccion:"Capacitación/"+item.nombre})
                            break;
                        case 4:
                            arrayTemp.push({label:item.nombre,to:"Capacitacion/Seccion/"+item.id+"/ListaObligatoria", visible:true,seccion:"Capacitación/"+item.nombre})
                            break;
                        case 5:
                            arrayTemp.push({label:item.nombre,to:"Capacitacion/Seccion/"+item.id+"/ListaLiderazgo", visible:true,seccion:"Capacitación/"+item.nombre})
                            break;
                        default:
                            break;
                    }
                })
                setListaSeccionCapacitacion(arrayTemp)
            })
           
        }
        if(!listaSeccionCapacitacion){
            obtenerSeccionCapacitacion();
            
        } 
    },[])

    useEffect(()=>{
        const obtenerSeccionClima =async ()=>{
            let jwt = window.localStorage.getItem("jwt");
            await ObtenerListaSeccionClima({jwt}).then(data=>{
                let arrayTemp = []
                data.map((item,i)=>{
                    switch (item.id) {
                        case 1:
                            arrayTemp.push({label:item.nombre,to:"Clima/Seccion/"+item.id+"/DetalleOnboarding", visible:true,seccion:"Clima/"+item.nombre})   
                            break;
                        case 2:
                            arrayTemp.push({label:item.nombre,to:"Clima/Seccion/"+item.id+"/DetalleActividades", visible:true,seccion:"Clima/"+item.nombre})   
                             break;
                        case 3:
                            arrayTemp.push({label:item.nombre,to:"Clima/Seccion/"+item.id+"/DetalleClimaLaboral/"+0, visible:true,seccion:"Clima/"+item.nombre})
                            break;
                        case 4:
                            arrayTemp.push({label:item.nombre,to:"Clima/Seccion/"+item.id+"/DetalleReconocimiento", visible:true,seccion:"Clima/"+item.nombre})
                            break;
                        case 5:
                            arrayTemp.push({label:item.nombre,to:"Clima/Seccion/"+item.id+"/DetalleClimaDiversidad/"+0, visible:true,seccion:"Clima/"+item.nombre})
                            break;
                        case 6:
                            arrayTemp.push({label:item.nombre,to:"Clima/Seccion/"+item.id+"/DetalleComunicaciones", visible:true,seccion:"Clima/"+item.nombre})   
                            break;
                        default:
                            break;
                    }
                })
                setListaSeccionClima(arrayTemp)

            })
        }
        if(!listaSeccionClima){
            obtenerSeccionClima();
            
        } 

    },[])
    

    useEffect(()=>{
        
        if(permisos.length > 0 && listaSeccionLiderIn && listaSeccionCapacitacion 
            && listaSeccionClima && perfil
            )
        {
            const menuitems = [
                {
                    label: "",
                    items: [
                        {
                            label: "Inicio",
                            // icon: <Iconly.Home set="light" />,
                            icon: <Iconsax.Home set="light" />,
                            to: "Home",
                            visible: true,
                            // visible: permisos.indexOf("verHome") > -1 ? true:false,
                            permiso:"verHome",
                            seccion:"Inicio"
                        },
                        {
                            label: "Mantenimiento",
                            icon: <Iconsax.UserEdit set="light" />,
                            to: "Usuario",
                            visible: permisos.indexOf("verusuario") > -1 ? true:false,
                            permiso:"verusuario",
                            seccion:"Mantenimiento de usuario"
                        },
                        {
                            label: "Grupos",
                            icon: <Iconsax.Profile2User set="light" />,
                            to: "Grupos",
                            //visible: permisos.indexOf("verGrupos") > -1 ? true:false,
                            visible: permisos.indexOf("aprobarPublicaciones") > -1 ? true:false,
                            permiso:"verGrupos",
                            seccion:"Grupos"
                        },
                        {
                            label: "Rol",
                            icon: <Iconsax.People set="light" />,
                            to: "Rol",
                            //visible: permisos.indexOf("verRoles") > -1 ? true:false,
                            visible:false,
                            permiso:"verRoles"
                        },
                        {
                            //label: "Somos In",
                            label: "Institucional",
                            icon: <Iconsax.Bank set="light" />,
                            visible: permisos.indexOf("verReglamentos") > -1 || permisos.indexOf("verDirectorio") > -1 ? true:false,
                            permiso:"verPermisosIn",
                            items :[
                                    {
                                        label: "Políticas y reglamentos",
                                        to: "SomosIn/ReglamentoGerencia",
                                        visible: permisos.indexOf("verReglamentos") > -1 ? true:false,
                                        permiso:"verReglamentos",
                                        seccion:"SomosIn/Politicas y Reglamentos"
                                    },
                                    {
                                        label: "Directorio In",
                                        to: "SomosIn/Directorio",
                                        visible: permisos.indexOf("verDirectorio") > -1 ? true:false,
                                        permiso:"verDirectorio",
                                        seccion:"SomosIn/Directorio"
                                    },
                                    {
                                        label: "Organigrama",
                                        to: "SomosIn/Organigrama",
                                        // visible: permisos.indexOf("verDirectorio") > -1 ? true:false,
                                        visible: true ,
                                        seccion:"SomosIn/Organigrama"
                                    },
                                ]
                        },
                        {
                            label: "Mis Gestiones IN",
                            icon: <Iconsax.DocumentText1 set="light" />,
                            visible: permisos.indexOf("verReglamentos") > -1 || permisos.indexOf("verDirectorio") > -1 ? true:false,
                            permiso:"verPermisosIn",
                            items :[
                                    {
                                        label: "Vacaciones",
                                        visible: true ,
                                        url: constantes.gdhenlineaUrlBase,
                                        pestaniaNueva:true,
                                        opcion:1
                                    },
                                    /* {
                                        label: "Descanso médico",
                                        visible: true ,
                                        url: constantes.gdhenlineaUrlBase,
                                        pestaniaNueva:true,
                                        opcion:2
                                    }, */
                                    {
                                        label: "Cuponera",
                                        visible: true ,
                                        url: constantes.gdhenlineaUrlCuponeraZegel,
                                        pestaniaNueva:true,
                                        opcion:3
                                    },
                                    {
                                        label: "Mesa de ayuda",
                                        visible: true ,
                                        url: constantes.gdhenlineaUrlMesaAyuda,
                                        pestaniaNueva:true,
                                        opcion:4
                                    },
                                ]
                        },
                        {
                            //label: "Clima y cultura",
                            label: "Somos IN",
                            icon: <Iconsax.Star set="light" />,
                            visible: permisos.indexOf("verClima") > -1 ? true:false,
                            
                            items : listaSeccionClima
                        },
                        {
                            label: "Lider IN",
                            icon: <Iconsax.People set="light" />,
                            //to: "LiderIn",
                            // visible:permisos.indexOf("verLiderIn") > -1 ? true:false,
                            visible:perfil.esJefe,
                            items : listaSeccionLiderIn
                        },
                        // {
                        //     label: "Tu desarrollo",
                        //     icon: <Iconsax.Rank set="light" />,
                        //     visible: permisos.indexOf("vertudesarrollo") > -1 ? true:false,
                        //     to: "Desarrollo/0",
                        //     seccion:"Desarrollo"
                        // },
                        {
                            label: "Desarrollo IN",
                            icon: <Iconsax.Rank set="light" />,
                            visible: permisos.indexOf("vertudesarrollo") > -1 ? true:false,
                            permiso:"verPermisosIn",
                            items :[
                                    {
                                        label: "Tu Desarrollo",
                                        to: "Desarrollo/0",
                                        visible: permisos.indexOf("vertudesarrollo") > -1 ? true:false,
                                        permiso:"vertudesarrollo",
                                        seccion:"Desarrollo/0"
                                    },
                                    {
                                        label: "Rankmi",
                                        visible: true ,
                                        url: constantes.gdhenlineaUrlrankmi,
                                        pestaniaNueva:true,
                                        opcion:2
                                    },
                                ]
                        },
                        {
                            label: "Capacitación",
                            icon: <Iconsax.Book1 set="light" />,
                            visible: permisos.indexOf("verCapacitacionGeneral") > -1 ? true:false,
                            items : listaSeccionCapacitacion
                        },
                        {
                            label: "Métricas",
                            icon: <Iconsax.PresentionChart set="light" />,
                            visible: permisos.indexOf("verMetricas") > -1 ? true:false,
                            //visible: true,
                            to: "Metricas",
                            //seccion:"SomosIn/Metricas"
                        },
                    ],
                },
            ];
            setMenuEva(menuitems)
        }

    },[permisos,listaSeccionLiderIn,listaSeccionCapacitacion,listaSeccionClima,perfil])

    return (
        <div className="layout-menu-container">
            <AppSubmenu items={menuEva} className="layout-menu"  onMenuItemClick={props.onMenuItemClick} root={true} role="menu" />
            
        </div>
    );
}
